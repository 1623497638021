import React from "react"
import { Link } from "gatsby"

import AdminLayout from "../components/adminPanelLayout"
import SEO from "../components/seo"

const AdminPanel = () => (
  <AdminLayout>
    <SEO title="Page two" />
    <h1>aktualnosci</h1>
  </AdminLayout>
)

export default AdminPanel
